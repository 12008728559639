import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../assets/images/logo.png';
import { Link } from '@mui/material';
import RegisterModal from '../RegisterModal';
import { useNavigate } from "react-router-dom";

const pages = ['Our Story', 'Contact', 'Sign Up', 'Login'];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
    setAnchorElNav(null);
  }
  const handleCloseRegisterModal = () => setOpenRegisterModal(false);

  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleClickOurStory = async () => {
    setAnchorElNav(null);
    if(document.getElementById('our-story')) {
      document.getElementById('our-story').scrollIntoView({behavior: 'smooth'})
    } else {
      navigate('/');
      await timeout(100);
      document.getElementById('our-story').scrollIntoView({behavior: 'smooth'});
    }
  };

  const handleClickLogin = () => {
    window.location.href = 'https://app.earthshepards.com';
  }

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'white', height: 98, display: 'flex', WebkitFlexDirection: 'row', alignItems: 'center' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Box onClick={() => {navigate('/')}}>
                <img src={Logo} alt="Logo" width={184}/>
            </Box>

            <Box sx={{ display: { xs: 'none', lg: 'flex'}}}>
                <Typography sx={{ color: 'gray', fontWeight: 'bold'}}>
                    Join now and start earning LIVE Rewards today!
                </Typography>
            </Box>

            <Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <ul style={{ display: 'flex'}}>
                    <li style={{ display: 'inline', paddingLeft: 10}}><a onClick={handleClickOurStory} style={{ textDecoration: 'none', color: '#007fcf', cursor: 'pointer' }}>Our Story</a></li>
                    {/* <li style={{ display: 'inline', paddingLeft: 20}}><a href='#' style={{ textDecoration: 'none', color: '#007fcf' }}>Contact</a></li> */}
                    <li style={{ display: 'inline', paddingLeft: 20}}><a href = 'https://forms.aweber.com/form/35/1056428835.htm' style={{ textDecoration: 'none', color: '#007fcf', cursor: 'pointer' }}>Sign Up</a></li>
                    <li style={{ display: 'inline', paddingLeft: 20}}><a href='https://app.earthshepards.com' style={{ textDecoration: 'none', color: '#007fcf' }}>Login</a></li>
                  </ul>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        sx={{ backgroundColor: 'white !important'}}
                        >
                        <MenuIcon sx={{ color: 'black' }}/>
                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                    >
                      <MenuItem onClick={handleClickOurStory}>
                        <Typography textAlign="center">Our Story</Typography>
                      </MenuItem>
                      {/* <MenuItem onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">Contact</Typography>
                      </MenuItem> */}
                      <MenuItem onClick={handleOpenRegisterModal}>
                        <Typography textAlign="center">Sign Up</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleClickLogin}>
                        <Typography textAlign="center">Login</Typography>
                      </MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Toolbar>
      </Container>
      <RegisterModal open={openRegisterModal} handleClose={handleCloseRegisterModal} />
    </AppBar>
  );
}
export default Header;